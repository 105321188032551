import React from "react";
// javascript plugin used to create scrollbars on windows
import Login from 'views/general/Pages/Login'
import PerfectScrollbar from "perfect-scrollbar";
import { Route, Switch, Redirect } from "react-router-dom";

//import { Header, Footer, Sidebar, ChatSidebar } from 'components'

import dashboardRoutes from "routes/general.jsx";

var ps;

class LoginPage extends React.Component {
    componentDidMount() {
        if (navigator.platform.indexOf("Win") > -1) {
            ps = new PerfectScrollbar(this.refs.mainPanel);
            document.body.classList.toggle("perfect-scrollbar-on");
        }
    }
    
    componentWillUnmount() {
        if (navigator.platform.indexOf("Win") > -1) {
            ps.destroy();
            document.body.classList.toggle("perfect-scrollbar-on");
        }
    }

    componentDidUpdate(e) {
        if (e.history.action === "PUSH") {
            this.refs.mainPanel.scrollTop = 0;
            document.scrollingElement.scrollTop = 0;
        }
    }

    render() {
        return (
            <div className="wrapper login_page">
                <div className="main-panel" ref="mainPanel">
                    <Login />
                </div>
            </div>
        );
    }
}

export default LoginPage;
