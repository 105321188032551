import General from 'views/general/Dashboard/General.jsx';
import Hospital from 'views/hospital/Dashboard/Hospital.jsx';
import Music from 'views/music/Dashboard/Music.jsx';
import Crm from 'views/crm/Dashboard/Crm.jsx';
import Social from 'views/social/Dashboard/Social.jsx';
import Freelance from 'views/freelance/Dashboard/Freelance.jsx';
import University from 'views/university/Dashboard/University.jsx';
import Ecommerce from 'views/ecommerce/Dashboard/Ecommerce.jsx';
import Blog from 'views/blog/Dashboard/Blog.jsx';

import Dashboard2 from 'views/general/Dashboard/Dashboard2.jsx';
import Dashboard3 from 'views/general/Dashboard/Dashboard3.jsx';
import Dashboard4 from 'views/general/Dashboard/Dashboard4.jsx';
import Dashboard5 from 'views/general/Dashboard/Dashboard5.jsx';
import Dashboard6 from 'views/general/Dashboard/Dashboard6.jsx';
import Dashboard7 from 'views/general/Dashboard/Dashboard7.jsx';
import Dashboard8 from 'views/general/Dashboard/Dashboard8.jsx';
import Dashboard9 from 'views/general/Dashboard/Dashboard9.jsx';


import Maps from 'views/general/Maps/Maps.jsx';
import UIVectorMap from 'views/general/Maps/VectorMap.jsx';

import Calendar from 'views/general/Calendar/Calendar.jsx';

import Mailinbox from 'views/general/Mail/Inbox.jsx';
import Mailcompose from 'views/general/Mail/Compose.jsx';
import Mailview from 'views/general/Mail/View.jsx';

import Widgetsmisc from 'views/general/Widgets/Misc.jsx';
import Widgetscountertiles from 'views/general/Widgets/CounterTiles.jsx';
import Widgetsprogresstiles from 'views/general/Widgets/ProgressTiles.jsx';
import Widgetssocialmedia from 'views/general/Widgets/SocialMedia.jsx';
import Widgetsloops from 'views/general/Widgets/Loops.jsx';

import Chartjsline from 'views/general/Chartjs/Line.jsx';
import Chartjsbar from 'views/general/Chartjs/Bar.jsx';
import Chartjspolar from 'views/general/Chartjs/Polar.jsx';
import Chartjspie from 'views/general/Chartjs/Pie.jsx';
import Chartjsradar from 'views/general/Chartjs/Radar.jsx';
import Chartjsdoughnut from 'views/general/Chartjs/Doughnut.jsx';
import Chartjsscatter from 'views/general/Chartjs/Scatter.jsx';
import Chartjsbubble from 'views/general/Chartjs/Bubble.jsx';
import Chartjshorizontalbar from 'views/general/Chartjs/Horizontalbar.jsx';

import FormElements from 'views/general/Forms/Elements.jsx';
//import FormGrid from 'views/general/Forms/Grid.jsx'; 
import FormPremade from 'views/general/Forms/Premade.jsx';
import FormDatePicker from 'views/general/Forms/DatePicker.jsx';
import FormTimePicker from 'views/general/Forms/TimePicker.jsx';
import FormDateTimePicker from 'views/general/Forms/DateTimePicker.jsx';
import FormDateRangePicker from 'views/general/Forms/DateRangePicker.jsx';
import FormColorPicker from 'views/general/Forms/ColorPicker.jsx';
import FormSlider from 'views/general/Forms/Slider.jsx';
import FormTagsinput from 'views/general/Forms/Tagsinput.jsx';
import FormAutosuggest from 'views/general/Forms/Autosuggest.jsx';
//import FormValidation from 'views/general/Forms/Validation.jsx'; 
import FormWysiwygEditor from 'views/general/Forms/WysiwygEditor.jsx';
import FormCKEditor from 'views/general/Forms/CKEditor.jsx';
import FormInputMask from 'views/general/Forms/InputMask.jsx';
import FormNumericInput from 'views/general/Forms/NumericInput.jsx';

import UITabs from 'views/general/UI/Tabs.jsx';
import UICollapse from 'views/general/UI/Collapse.jsx';
import UIProgress from 'views/general/UI/Progress.jsx';
import UIButtons from 'views/general/UI/Buttons.jsx';
import UIModals from 'views/general/UI/Modals.jsx';
import UIBadges from 'views/general/UI/Badges.jsx';
import UIAlerts from 'views/general/UI/Alerts.jsx';
import UINotifications from 'views/general/UI/Notifications.jsx';
import UIBreadcrumbs from 'views/general/UI/Breadcrumbs.jsx';
import UIButtonDropdowns from 'views/general/UI/ButtonDropdowns.jsx';
import UIButtonGroup from 'views/general/UI/ButtonGroup.jsx';
//import UICards from 'views/general/UI/Cards.jsx'; 
import UICarousel from 'views/general/UI/Carousel.jsx';
import UIDropdowns from 'views/general/UI/Dropdowns.jsx';
import UIFade from 'views/general/UI/Fade.jsx';
import UIJumbotron from 'views/general/UI/Jumbotron.jsx';
import UIGrid from 'views/general/UI/Grid.jsx';
import UIListGroup from 'views/general/UI/ListGroup.jsx';
//import UINavbar from 'views/general/UI/Navbar.jsx'; 
//import UINav from 'views/general/UI/Nav.jsx'; 
import UIPagination from 'views/general/UI/Pagination.jsx';
import UIPopovers from 'views/general/UI/Popovers.jsx';
import UITooltips from 'views/general/UI/Tooltips.jsx';

import UITypography from 'views/general/UI/Typography.jsx';
import UIVideoEmbeds from 'views/general/UI/VideoEmbeds.jsx';
import UICode from 'views/general/UI/Code.jsx';
//import UIResponsiveImages from 'views/general/UI/ResponsiveImages.jsx';

import FontAwesome from 'views/general/Icons/FontAwesome.jsx';
import SimpleLineIcons from 'views/general/Icons/SimpleLineIcons.jsx';



import UITables from 'views/general/Tables/Tables.jsx';
/*import UIDatatables from 'views/general/Tables/Datatables.jsx';*/
import UIDatagrid from 'views/general/Tables/Datagrid.jsx';
/*import UIDatagridsort from 'views/general/Tables/Datagridsort.jsx';*/
import UIBSDatatable from 'views/general/Tables/BSDatatable.jsx';

import Login from 'views/general/Pages/Login.jsx';
import Register from 'views/general/Pages/Register.jsx';
import LockScreen from 'views/general/Pages/LockScreen.jsx';

import Page403 from 'views/general/Pages/403.jsx';
import Page404 from 'views/general/Pages/404.jsx';
import Page405 from 'views/general/Pages/405.jsx';
import Page408 from 'views/general/Pages/408.jsx';
import Page500 from 'views/general/Pages/500.jsx';
import Page503 from 'views/general/Pages/503.jsx';
import Offline from 'views/general/Pages/Offline.jsx';


import Blogs from 'views/general/Blogs/Blogs.jsx';
import BlogSearch from 'views/general/Blogs/Search.jsx';
import SingleBlog from 'views/general/Blogs/SingleBlog.jsx';

import UserProfile from 'views/general/SocialMedia/UserProfile.jsx';
import Members from 'views/general/SocialMedia/Members.jsx';

import CenteredTimeline from 'views/general/Timeline/Centered.jsx';
import LeftTimeline from 'views/general/Timeline/Leftalign.jsx';

import PricingExpanded from 'views/general/Pricing/Expanded.jsx';
import PricingNarrow from 'views/general/Pricing/Narrow.jsx';

import Invoice from 'views/general/Invoice/Invoice.jsx';
import MasonryGallery from 'views/general/Gallery/Masonry.jsx';

import FileDropzone from 'views/general/FileUploader/Dropzone.jsx';

//import Doctor from 'views/hospital/Doctor/Doctor.jsx';

import DefaultLayout from 'views/general/PageLayouts/DefaultLayout.jsx';
import FoldedMenu from 'views/general/PageLayouts/FoldedMenu.jsx';
import TransparentLayout from 'views/general/PageLayouts/TransparentLayout.jsx';
import LightMenu from 'views/general/PageLayouts/LightMenu.jsx';
import ChatOpen from 'views/general/PageLayouts/ChatOpen.jsx';
import Layout1 from 'views/general/PageLayouts/Layout1.jsx';
import Layout2 from 'views/general/PageLayouts/Layout2.jsx';
import Layout3 from 'views/general/PageLayouts/Layout3.jsx';
import Layout4 from 'views/general/PageLayouts/Layout4.jsx';
import Layout5 from 'views/general/PageLayouts/Layout5.jsx';
import Layout6 from 'views/general/PageLayouts/Layout6.jsx';
import Layout7 from 'views/general/PageLayouts/Layout7.jsx';
import Layout8 from 'views/general/PageLayouts/Layout8.jsx';
import Layout9 from 'views/general/PageLayouts/Layout9.jsx';
import Layout10 from 'views/general/PageLayouts/Layout10.jsx';
import Layout11 from 'views/general/PageLayouts/Layout11.jsx';
import Layout12 from 'views/general/PageLayouts/Layout12.jsx';
import Layout13 from 'views/general/PageLayouts/Layout13.jsx';
import Layout14 from 'views/general/PageLayouts/Layout14.jsx';
import Layout15 from 'views/general/PageLayouts/Layout15.jsx';

//import {menuStyle} from 'variables/settings/blog.jsx';

var BASEDIR = '/general';

var dashRoutes = [

    //{ path: "#", name: "Main", type: "navgroup"},
    /* { path: BASEDIR+"/dashboard", name: "Dashboard", icon: "dashboard", badge: "", component: General },*/

    {
        path: "#",
        name: "Dashboards",
        icon: "speedometer",
        type: "dropdown",
        parentid: "dashboards",
        child: [{
                path: BASEDIR + "/dashboard",
                name: "Dashboard 1"
            },
            {
                path: BASEDIR + "/dashboard2",
                name: "Dashboard 2"
            },
            {
                path: BASEDIR + "/dashboard3",
                name: "Dashboard 3"
            },
            {
                path: BASEDIR + "/dashboard4",
                name: "Dashboard 4"
            },
            {
                path: BASEDIR + "/dashboard5",
                name: "Dashboard 5"
            },
            {
                path: BASEDIR + "/dashboard6",
                name: "Dashboard 6"
            },
            {
                path: BASEDIR + "/dashboard7",
                name: "Dashboard 7"
            },
            {
                path: BASEDIR + "/dashboard8",
                name: "Dashboard 8"
            },
            {
                path: BASEDIR + "/dashboard9",
                name: "Dashboard 9"
            },
        ]
    },

    {
        path: BASEDIR + "/dashboard",
        component: General,
        type: "child"
    },
    {
        path: BASEDIR + "/dashboard2",
        component: Dashboard2,
        type: "child"
    },
    {
        path: BASEDIR + "/dashboard3",
        component: Dashboard3,
        type: "child"
    },
    {
        path: BASEDIR + "/dashboard4",
        component: Dashboard4,
        type: "child"
    },
    {
        path: BASEDIR + "/dashboard5",
        component: Dashboard5,
        type: "child"
    },
    {
        path: BASEDIR + "/dashboard6",
        component: Dashboard6,
        type: "child"
    },
    {
        path: BASEDIR + "/dashboard7",
        component: Dashboard7,
        type: "child"
    },
    {
        path: BASEDIR + "/dashboard8",
        component: Dashboard8,
        type: "child"
    },
    {
        path: BASEDIR + "/dashboard9",
        component: Dashboard9,
        type: "child"
    },


    {
        path: "#",
        name: "Multi Purpose",
        icon: "layers",
        type: "dropdown",
        parentid: "multipurpose",
        child: [{
                path: BASEDIR + "/dashboard",
                name: "General"
            },
            {
                path: BASEDIR + "/hospital/dashboard",
                name: "Hospital"
            },
            {
                path: BASEDIR + "/music/dashboard",
                name: "Music"
            },
            {
                path: BASEDIR + "/crm/dashboard",
                name: "CRM"
            },
            {
                path: BASEDIR + "/social/dashboard",
                name: "Social Media"
            },
            {
                path: BASEDIR + "/freelance/dashboard",
                name: "Freelance"
            },
            {
                path: BASEDIR + "/university/dashboard",
                name: "University"
            },
            {
                path: BASEDIR + "/ecommerce/dashboard",
                name: "Ecommerce"
            },
            {
                path: BASEDIR + "/blog/dashboard",
                name: "Blog"
            },
        ]
    },

    {
        path: BASEDIR + "/dashboard",
        component: General,
        type: "child"
    },
    {
        path: BASEDIR + "/hospital/dashboard",
        component: Hospital,
        type: "child"
    },
    {
        path: BASEDIR + "/music/dashboard",
        component: Music,
        type: "child"
    },
    {
        path: BASEDIR + "/crm/dashboard",
        component: Crm,
        type: "child"
    },
    {
        path: BASEDIR + "/social/dashboard",
        component: Social,
        type: "child"
    },
    {
        path: BASEDIR + "/freelance/dashboard",
        component: Freelance,
        type: "child"
    },
    {
        path: BASEDIR + "/university/dashboard",
        component: University,
        type: "child"
    },
    {
        path: BASEDIR + "/ecommerce/dashboard",
        component: Ecommerce,
        type: "child"
    },
    {
        path: BASEDIR + "/blog/dashboard",
        component: Blog,
        type: "child"
    },


    //{ path: "#", name: "Applications", type: "navgroup"},
    {
        path: BASEDIR + "/calendar",
        name: "Calendar",
        icon: "calendar",
        component: Calendar
    },

    {
        path: "#",
        name: "Mail Box",
        icon: "envelope",
        type: "dropdown",
        parentid: "mailbox",
        child: [{
                path: BASEDIR + "/mail-inbox",
                name: "Inbox"
            },
            {
                path: BASEDIR + "/mail-compose",
                name: "Compose"
            },
            {
                path: BASEDIR + "/mail-view",
                name: "View"
            },
        ]
    },
    {
        path: BASEDIR + "/mail-inbox",
        component: Mailinbox,
        type: "child"
    },
    {
        path: BASEDIR + "/mail-compose",
        component: Mailcompose,
        type: "child"
    },
    {
        path: BASEDIR + "/mail-view",
        component: Mailview,
        type: "child"
    },


    {
        path: "#",
        name: "Widgets",
        icon: "puzzle",
        type: "dropdown",
        parentid: "widgets",
        child: [{
                path: BASEDIR + "/widget-loops",
                name: "Loops"
            },
            {
                path: BASEDIR + "/widget-tiles-counter",
                name: "Counter Tiles"
            },
            {
                path: BASEDIR + "/widget-tiles-progress",
                name: "Progress Tiles"
            },
            {
                path: BASEDIR + "/widget-socialmedia",
                name: "Social Media"
            },
            {
                path: BASEDIR + "/widget-misc",
                name: "Miscellaneous"
            },
            /*{ path: BASEDIR+"/widget-graphs", name: "Graphs"},
            { path: BASEDIR+"/widget-todo", name: "To Do Tasks"},*/
        ]
    },
    {
        path: BASEDIR + "/widget-loops",
        component: Widgetsloops,
        type: "child"
    },
    {
        path: BASEDIR + "/widget-tiles-counter",
        component: Widgetscountertiles,
        type: "child"
    },
    {
        path: BASEDIR + "/widget-tiles-progress",
        component: Widgetsprogresstiles,
        type: "child"
    },
    {
        path: BASEDIR + "/widget-socialmedia",
        component: Widgetssocialmedia,
        type: "child"
    },
    {
        path: BASEDIR + "/widget-misc",
        component: Widgetsmisc,
        type: "child"
    },
    /*{ path: BASEDIR+"/widget-graphs", component: UITypography, type: "child"},
    { path: BASEDIR+"/widget-todo", component: UITypography, type: "child"},*/


    //{ path: "#", name: "Data Visualization", type: "navgroup"},
    {
        path: "#",
        name: "Charts",
        icon: "chart",
        type: "dropdown",
        parentid: "charts",
        child: [{
                path: BASEDIR + "/charts-chartjs-line",
                name: "Line & Area"
            },
            {
                path: BASEDIR + "/charts-chartjs-bar",
                name: "Bar"
            },
            {
                path: BASEDIR + "/charts-chartjs-polar",
                name: "Polar"
            },
            {
                path: BASEDIR + "/charts-chartjs-pie",
                name: "Pie"
            },
            {
                path: BASEDIR + "/charts-chartjs-radar",
                name: "Radar"
            },
            {
                path: BASEDIR + "/charts-chartjs-doughnut",
                name: "Doughnut"
            },
            {
                path: BASEDIR + "/charts-chartjs-scatter",
                name: "Scatter"
            },
            {
                path: BASEDIR + "/charts-chartjs-bubble",
                name: "Bubble"
            },
            {
                path: BASEDIR + "/charts-chartjs-horizontalbar",
                name: "Horizontalbar"
            },
        ]
    },
    {
        path: BASEDIR + "/charts-chartjs-line",
        component: Chartjsline,
        type: "child"
    },
    {
        path: BASEDIR + "/charts-chartjs-bar",
        component: Chartjsbar,
        type: "child"
    },
    {
        path: BASEDIR + "/charts-chartjs-polar",
        component: Chartjspolar,
        type: "child"
    },
    {
        path: BASEDIR + "/charts-chartjs-pie",
        component: Chartjspie,
        type: "child"
    },
    {
        path: BASEDIR + "/charts-chartjs-radar",
        component: Chartjsradar,
        type: "child"
    },
    {
        path: BASEDIR + "/charts-chartjs-doughnut",
        component: Chartjsdoughnut,
        type: "child"
    },
    {
        path: BASEDIR + "/charts-chartjs-scatter",
        component: Chartjsscatter,
        type: "child"
    },
    {
        path: BASEDIR + "/charts-chartjs-bubble",
        component: Chartjsbubble,
        type: "child"
    },
    {
        path: BASEDIR + "/charts-chartjs-horizontalbar",
        component: Chartjshorizontalbar,
        type: "child"
    },




    //{ path: "#", name: "Forms", type: "navgroup"},

    {
        path: "#",
        name: "Forms",
        icon: "note",
        type: "dropdown",
        parentid: "forms",
        child: [

            {
                path: BASEDIR + "/form-elements",
                name: "Field Elements"
            },
            {
                path: BASEDIR + "/form-elements-premade",
                name: "Pre Made Forms"
            },
            /* { path: BASEDIR+"/form-elements-icheck", name: "Checkbox & Radio"},*/
            /*{ path: BASEDIR+"/form-elements-grid", name: "Form Grid"},*/
            {
                path: BASEDIR + "/form-components-datepicker",
                name: "Date picker"
            },
            {
                path: BASEDIR + "/form-components-timepicker",
                name: "Time picker"
            },
            {
                path: BASEDIR + "/form-components-datetimepicker",
                name: "Date Time picker"
            },
            {
                path: BASEDIR + "/form-components-daterange",
                name: "Date Range"
            },
            /*{ path: BASEDIR+"/form-components-spinners", name: "Spinners"},*/
            /*{ path: BASEDIR+"/form-components-switches", name: "Switches"},*/
            /*{ path: BASEDIR+"/form-components-select2", name: "Select2"},*/
            {
                path: BASEDIR + "/form-components-typeahead",
                name: "Typeahead / Auto Suggest"
            },
            /*{ path: BASEDIR+"/form-components-multiselect", name: "Multi Select"},*/
            {
                path: BASEDIR + "/form-components-numericinput",
                name: "Numeric Input"
            },
            /*{ path: BASEDIR+"/form-editors-inline", name: "Inline editor"},*/
            /*{ path: BASEDIR+"/form-editors-markdown", name: "Markdown editor"},*/
            {
                path: BASEDIR + "/form-masks-input",
                name: "Input Masks"
            },
            /*{ path: BASEDIR+"/form-validation", name: "Form Validations"},*/

        ]
    },
    {
        path: BASEDIR + "/form-elements",
        component: FormElements,
        type: "child"
    },
    {
        path: BASEDIR + "/form-elements-premade",
        component: FormPremade,
        type: "child"
    },
    /*{ path: BASEDIR+"/form-elements-icheck", component: UITypography, type: "child"},*/
    /*{ path: BASEDIR+"/form-elements-grid", component: FormGrid, type: "child"},*/
    {
        path: BASEDIR + "/form-components-datepicker",
        component: FormDatePicker,
        type: "child"
    },
    {
        path: BASEDIR + "/form-components-timepicker",
        component: FormTimePicker,
        type: "child"
    },
    {
        path: BASEDIR + "/form-components-datetimepicker",
        component: FormDateTimePicker,
        type: "child"
    },
    {
        path: BASEDIR + "/form-components-daterange",
        component: FormDateRangePicker,
        type: "child"
    },
    /*{ path: BASEDIR+"/form-components-spinners", component: UITypography, type: "child"},*/
    /*{ path: BASEDIR+"/form-components-switches", component: UITypography, type: "child"},*/
    /*{ path: BASEDIR+"/form-components-select2", component: UITypography, type: "child"},*/
    {
        path: BASEDIR + "/form-components-typeahead",
        component: FormAutosuggest,
        type: "child"
    },
    {
        path: BASEDIR + "/form-components-numericinput",
        component: FormNumericInput,
        type: "child"
    },
    /*{ path: BASEDIR+"/form-components-multiselect", component: UITypography, type: "child"},*/
    /*{ path: BASEDIR+"/form-editors-inline", component: UITypography, type: "child"},*/
    /*{ path: BASEDIR+"/form-editors-markdown", component: UITypography, type: "child"},*/
    {
        path: BASEDIR + "/form-masks-input",
        component: FormInputMask,
        type: "child"
    },
    /*{ path: BASEDIR+"/form-validation", component: FormValidation, type: "child"},*/


    {
        path: "#",
        name: "Components",
        icon: "organization",
        type: "dropdown",
        parentid: "components",
        child: [{
                path: BASEDIR + "/ui-modals",
                name: "Modals"
            },
            {
                path: BASEDIR + "/ui-notifications",
                name: "Notifications"
            },
            {
                path: BASEDIR + "/ui-tooltips",
                name: "Tooltips"
            },
            {
                path: BASEDIR + "/ui-popovers",
                name: "Popovers"
            },
            {
                path: BASEDIR + "/ui-carousel",
                name: "Carousel Slider"
            },
            {
                path: BASEDIR + "/ui-fade",
                name: "Fade"
            },
            {
                path: BASEDIR + "/ui-dropdowns",
                name: "Dropdowns"
            },
            {
                path: BASEDIR + "/ui-buttondropdowns",
                name: "Button Dropdowns"
            },
            {
                path: BASEDIR + "/ui-tabs",
                name: "Tabs"
            },
            {
                path: BASEDIR + "/ui-collapse",
                name: "Collapse"
            },
        ]
    },
    {
        path: BASEDIR + "/ui-modals",
        component: UIModals,
        type: "child"
    },
    {
        path: BASEDIR + "/ui-notifications",
        component: UINotifications,
        type: "child"
    },
    {
        path: BASEDIR + "/ui-tooltips",
        component: UITooltips,
        type: "child"
    },
    {
        path: BASEDIR + "/ui-popovers",
        component: UIPopovers,
        type: "child"
    },
    {
        path: BASEDIR + "/ui-carousel",
        component: UICarousel,
        type: "child"
    },
    {
        path: BASEDIR + "/ui-fade",
        component: UIFade,
        type: "child"
    },
    {
        path: BASEDIR + "/ui-dropdowns",
        component: UIDropdowns,
        type: "child"
    },
    {
        path: BASEDIR + "/ui-buttondropdowns",
        component: UIButtonDropdowns,
        type: "child"
    },
    {
        path: BASEDIR + "/ui-tabs",
        component: UITabs,
        type: "child"
    },
    {
        path: BASEDIR + "/ui-collapse",
        component: UICollapse,
        type: "child"
    },



    {
        path: "#",
        name: "Access & Error Pages",
        icon: "key",
        type: "dropdown",
        parentid: "accesspages",
        child: [

            {
                path: BASEDIR + "/login",
                name: "Login"
            },
            {
                path: BASEDIR + "/lockscreen",
                name: "Lock Screen"
            },
            {
                path: BASEDIR + "/register",
                name: "Registration"
            },
            {
                path: BASEDIR + "/403",
                name: "403"
            },
            {
                path: BASEDIR + "/404",
                name: "404"
            },
            {
                path: BASEDIR + "/405",
                name: "405"
            },
            {
                path: BASEDIR + "/408",
                name: "408"
            },
            {
                path: BASEDIR + "/500",
                name: "500"
            },
            {
                path: BASEDIR + "/503",
                name: "503"
            },
            {
                path: BASEDIR + "/offline",
                name: "Offline"
            },

        ]
    },
    {
        path: BASEDIR + "/login",
        component: Login,
        type: "child"
    },
    {
        path: BASEDIR + "/lockscreen",
        component: LockScreen,
        type: "child"
    },
    {
        path: BASEDIR + "/register",
        component: Register,
        type: "child"
    },
    {
        path: BASEDIR + "/403",
        component: Page403,
        type: "child"
    },
    {
        path: BASEDIR + "/404",
        component: Page404,
        type: "child"
    },
    {
        path: BASEDIR + "/405",
        component: Page405,
        type: "child"
    },
    {
        path: BASEDIR + "/408",
        component: Page408,
        type: "child"
    },
    {
        path: BASEDIR + "/500",
        component: Page500,
        type: "child"
    },
    {
        path: BASEDIR + "/503",
        component: Page503,
        type: "child"
    },
    {
        path: BASEDIR + "/offline",
        component: Offline,
        type: "child"
    },


    {
        path: "#",
        name: "Pages",
        icon: "notebook",
        type: "dropdown",
        parentid: "pages",
        child: [

            {
                path: BASEDIR + "/ui-blogs",
                name: "Blogs"
            },
            {
                path: BASEDIR + "/ui-search",
                name: "Search"
            },
            {
                path: BASEDIR + "/ui-blog-item",
                name: "Single Blog"
            },
            {
                path: BASEDIR + "/ui-profile",
                name: "User Profile"
            },
            {
                path: BASEDIR + "/ui-members",
                name: "Members"
            },
            {
                path: BASEDIR + "/ui-masonry",
                name: "Masonry"
            },
            {
                path: BASEDIR + "/ui-timeline-centered",
                name: "Centered timeline"
            },
            {
                path: BASEDIR + "/ui-timeline-left",
                name: "Left Aligned timeline"
            },
            {
                path: BASEDIR + "/ui-pricing-expanded",
                name: "Expanded"
            },
            {
                path: BASEDIR + "/ui-pricing-narrow",
                name: "Narrow"
            },
            {
                path: BASEDIR + "/ui-invoice",
                name: "Invoice"
            },
        ]
    },
    {
        path: BASEDIR + "/ui-blogs",
        component: Blogs,
        type: "child"
    },
    {
        path: BASEDIR + "/ui-search",
        component: BlogSearch,
        type: "child"
    },
    {
        path: BASEDIR + "/ui-blog-item",
        component: SingleBlog,
        type: "child"
    },
    {
        path: BASEDIR + "/ui-profile",
        component: UserProfile,
        type: "child"
    },
    {
        path: BASEDIR + "/ui-members",
        component: Members,
        type: "child"
    },
    {
        path: BASEDIR + "/ui-masonry",
        component: MasonryGallery,
        type: "child"
    },
    {
        path: BASEDIR + "/ui-timeline-centered",
        component: CenteredTimeline,
        type: "child"
    },
    {
        path: BASEDIR + "/ui-timeline-left",
        component: LeftTimeline,
        type: "child"
    },
    {
        path: BASEDIR + "/ui-pricing-expanded",
        component: PricingExpanded,
        type: "child"
    },
    {
        path: BASEDIR + "/ui-pricing-narrow",
        component: PricingNarrow,
        type: "child"
    },
    {
        path: BASEDIR + "/ui-invoice",
        component: Invoice,
        type: "child"
    },
    /*{ 
        path: "#", name: "Support", icon: "question-circle", type: "dropdown", parentid:"",
        child: [
    
        { path: BASEDIR+"/ui-faq", name: "FAQ"},
        { path: BASEDIR+"/ui-tocify", name: "Documentation"},
        ]
    },
        { path: BASEDIR+"/ui-faq", component: UITypography, type: "child"},
        { path: BASEDIR+"/ui-tocify", component: UITypography, type: "child"},*/
    /*{ path: BASEDIR+"/ui-invoice-list", component: UITypography, type: "child"},
    { path: BASEDIR+"/ui-invoice-add", component: UITypography, type: "child"},
    { path: BASEDIR+"/ui-invoice-edit", component: UITypography, type: "child"},*/
    //{ path: "#", name: "User Interface", type: "navgroup"},

    {
        path: "#",
        name: "UI Elements",
        icon: "screen-desktop",
        type: "dropdown",
        parentid: "ui",
        child: [{
                path: BASEDIR + "/ui-typography",
                name: "Typography"
            },
            {
                path: BASEDIR + "/ui-buttons",
                name: "Buttons"
            },
            /*{ path: BASEDIR+"/ui-grids", name: "Grids"},
            { path: BASEDIR+"/ui-panels", name: "Draggable Panels"},*/
            {
                path: BASEDIR + "/ui-embeds",
                name: "Video Embeds"
            },
            {
                path: BASEDIR + "/ui-code",
                name: "Code styles"
            },
            /*{ path: BASEDIR+"/ui-group-list", name: "Group Listing"},*/
            /*{ path: BASEDIR+"/ui-responsive-images", name: "Responsive Images"},*/
            {
                path: BASEDIR + "/ui-progress",
                name: "Progress Bars"
            },
            /*{ path: BASEDIR+"/ui-cards", name: "Cards"},*/
            {
                path: BASEDIR + "/ui-alerts",
                name: "Alerts"
            },
            /*{ path: BASEDIR+"/ui-navbars", name: "Navbars"},
            { path: BASEDIR+"/ui-nav", name: "Nav"},*/
            /*{ path: BASEDIR+"/ui-tree", name: "Tree"},*/
            {
                path: BASEDIR + "/ui-breadcrumbs",
                name: "Breadcrumbs"
            },
            {
                path: BASEDIR + "/ui-grid",
                name: "Grid"
            },
            {
                path: BASEDIR + "/ui-listgroup",
                name: "List Group"
            },
            {
                path: BASEDIR + "/ui-jumbotron",
                name: "Jumbotron"
            },
            {
                path: BASEDIR + "/ui-buttongroup",
                name: "Button Group"
            },
            {
                path: BASEDIR + "/ui-pagination",
                name: "Pagination"
            },
            {
                path: BASEDIR + "/ui-badges",
                name: "Badges"
            },
        ]
    },
    {
        path: BASEDIR + "/ui-typography",
        component: UITypography,
        type: "child"
    },
    {
        path: BASEDIR + "/ui-buttons",
        component: UIButtons,
        type: "child"
    },
    /*{ path: BASEDIR+"/ui-grids", component: UITypography, type: "child"},
    { path: BASEDIR+"/ui-panels", component: UITypography, type: "child"},*/
    {
        path: BASEDIR + "/ui-embeds",
        component: UIVideoEmbeds,
        type: "child"
    },
    {
        path: BASEDIR + "/ui-code",
        component: UICode,
        type: "child"
    },
    /*{ path: BASEDIR+"/ui-group-list", component: UITypography, type: "child"},*/
    /*{ path: BASEDIR+"/ui-responsive-images", component: UIResponsiveImages, type: "child"},*/
    {
        path: BASEDIR + "/ui-progress",
        component: UIProgress,
        type: "child"
    },
    /* { path: BASEDIR+"/ui-cards", component: UICards, type: "child"},*/
    {
        path: BASEDIR + "/ui-alerts",
        component: UIAlerts,
        type: "child"
    },
    /*{ path: BASEDIR+"/ui-navbars", component: UINavbar, type: "child"},
    { path: BASEDIR+"/ui-nav", component: UINav, type: "child"},*/
    /*{ path: BASEDIR+"/ui-tree", component: UITypography, type: "child"},*/
    {
        path: BASEDIR + "/ui-breadcrumbs",
        component: UIBreadcrumbs,
        type: "child"
    },
    {
        path: BASEDIR + "/ui-grid",
        component: UIGrid,
        type: "child"
    },
    {
        path: BASEDIR + "/ui-listgroup",
        component: UIListGroup,
        type: "child"
    },
    {
        path: BASEDIR + "/ui-jumbotron",
        component: UIJumbotron,
        type: "child"
    },
    {
        path: BASEDIR + "/ui-buttongroup",
        component: UIButtonGroup,
        type: "child"
    },
    {
        path: BASEDIR + "/ui-pagination",
        component: UIPagination,
        type: "child"
    },
    {
        path: BASEDIR + "/ui-badges",
        component: UIBadges,
        type: "child"
    },



    {
        path: "#",
        name: "Plugins",
        icon: "anchor",
        type: "dropdown",
        parentid: "plugins",
        child: [{
                path: BASEDIR + "/form-components-sliders",
                name: "Sliders"
            },
            {
                path: BASEDIR + "/form-components-colorpicker",
                name: "Color picker"
            },
            {
                path: BASEDIR + "/form-components-tagsinput",
                name: "Tags Input"
            },
            {
                path: BASEDIR + "/form-editors-wysiwyg",
                name: "WYSIWYG Draft"
            },
            {
                path: BASEDIR + "/form-editors-ckeditor",
                name: "Ckeditor"
            },
            {
                path: BASEDIR + "/form-fileupload-dropzone",
                name: "DropZone (Drag & Drop)"
            },
        ]
    },
    {
        path: BASEDIR + "/form-components-sliders",
        component: FormSlider,
        type: "child"
    },
    {
        path: BASEDIR + "/form-components-colorpicker",
        component: FormColorPicker,
        type: "child"
    },
    {
        path: BASEDIR + "/form-components-tagsinput",
        component: FormTagsinput,
        type: "child"
    },
    {
        path: BASEDIR + "/form-editors-wysiwyg",
        component: FormWysiwygEditor,
        type: "child"
    },
    {
        path: BASEDIR + "/form-editors-ckeditor",
        component: FormCKEditor,
        type: "child"
    },
    {
        path: BASEDIR + "/form-fileupload-dropzone",
        component: FileDropzone,
        type: "child"
    },




    {
        path: "#",
        name: "Icon Sets",
        icon: "mustache",
        type: "dropdown",
        parentid: "icons",
        child: [

            /*{ path: BASEDIR+"/ui-icons", name: "Icon Styles"},*/
            {
                path: BASEDIR + "/ui-fontawesome",
                name: "Font Awesome"
            },
            {
                path: BASEDIR + "/ui-simplelineicons",
                name: "Simple Line Icons"
            },
            /*{ path: BASEDIR+"/ui-glyphicons", name: "Glyph Icons"},*/
        ]
    },
    /*{ path: BASEDIR+"/ui-icons", component: UITypography, type: "child"},*/
    {
        path: BASEDIR + "/ui-fontawesome",
        component: FontAwesome,
        type: "child"
    },
    {
        path: BASEDIR + "/ui-simplelineicons",
        component: SimpleLineIcons,
        type: "child"
    },
    /*{ path: BASEDIR+"/ui-glyphicons", component: UITypography, type: "child"},*/
    {
        path: "#",
        name: "Tables",
        icon: "grid",
        type: "dropdown",
        parentid: "tables",
        child: [

            {
                path: BASEDIR + "/tables-basic",
                name: "Basic tables"
            },
            {
                path: BASEDIR + "/tables-datagrid",
                name: "Data Grid"
            },
            {
                path: BASEDIR + "/tables-bsdatatable",
                name: "Datatable"
            },
            /*{ path: BASEDIR+"/tables-datagridsort", name: "Data Grid Sorting"},*/
            /*{ path: BASEDIR+"/tables-data", name: "Data Tables"},*/
            /*{ path: BASEDIR+"/tables-responsive", name: "Responsive Tables"},*/
        ]
    },
    {
        path: BASEDIR + "/tables-basic",
        component: UITables,
        type: "child"
    },
    {
        path: BASEDIR + "/tables-datagrid",
        component: UIDatagrid,
        type: "child"
    },
    {
        path: BASEDIR + "/tables-bsdatatable",
        component: UIBSDatatable,
        type: "child"
    },

    /*{ path: BASEDIR+"/tables-datagridsort", component: UIDatagridsort, type: "child"},*/
    /*{ path: BASEDIR+"/tables-data", component: UIDatatables, type: "child"},*/
    /*{ path: BASEDIR+"/tables-responsive", component: UITypography, type: "child"},*/



    //{ path: "#", name: "Maps", type: "navgroup"},

    {
        path: "#",
        name: "Maps",
        icon: "location-pin",
        type: "dropdown",
        parentid: "maps",
        child: [

            {
                path: BASEDIR + "/ui-vectormaps",
                name: "Vector World Map"
            },
            {
                path: BASEDIR + "/ui-googlemaps",
                name: "Google Maps"
            },
            /*{ path: BASEDIR+"/tables-data", name: "Data Tables"},*/
            /*{ path: BASEDIR+"/tables-responsive", name: "Responsive Tables"},*/
        ]
    },
    {
        path: BASEDIR + "/ui-vectormaps",
        component: UIVectorMap,
        type: "child"
    },
    {
        path: BASEDIR + "/ui-googlemaps",
        component: Maps,
        type: "child"
    },
    /*{ path: BASEDIR+"/tables-datagrid", component: UIDatagrid, type: "child"},*/
    /*{ path: BASEDIR+"/tables-data", component: UIDatatables, type: "child"},*/
    /*{ path: BASEDIR+"/tables-responsive", component: UITypography, type: "child"},*/



    {
        path: "#",
        name: "Layouts",
        icon: "bag",
        type: "dropdown",
        parentid: "layouts",
        child: [{
                path: BASEDIR + "/defaultlayout",
                name: "Default Layout"
            },
            {
                path: BASEDIR + "/foldedmenu",
                name: "Folded Menu"
            },
            {
                path: BASEDIR + "/transparentlayout",
                name: "Transparent Layout"
            },
            {
                path: BASEDIR + "/lightmenu",
                name: "Light Menu"
            },
            {
                path: BASEDIR + "/chatopen",
                name: "Chat Open"
            },
            {
                path: BASEDIR + "/layout1",
                name: "Layout 1"
            },
            {
                path: BASEDIR + "/layout2",
                name: "Layout 2"
            },
            {
                path: BASEDIR + "/layout3",
                name: "Layout 3"
            },
            {
                path: BASEDIR + "/layout4",
                name: "Layout 4"
            },
            {
                path: BASEDIR + "/layout5",
                name: "Layout 5"
            },
            {
                path: BASEDIR + "/layout6",
                name: "Layout 6"
            },
            {
                path: BASEDIR + "/layout7",
                name: "Layout 7"
            },
            {
                path: BASEDIR + "/layout8",
                name: "Layout 8"
            },
            {
                path: BASEDIR + "/layout9",
                name: "Layout 9"
            },
            {
                path: BASEDIR + "/layout10",
                name: "Layout 10"
            },
            {
                path: BASEDIR + "/layout11",
                name: "Layout 11"
            },
            {
                path: BASEDIR + "/layout12",
                name: "Layout 12"
            },
            {
                path: BASEDIR + "/layout13",
                name: "Layout 13"
            },
            {
                path: BASEDIR + "/layout14",
                name: "Layout 14"
            },
            {
                path: BASEDIR + "/layout15",
                name: "Layout 15"
            },
        ]
    },

    {
        path: BASEDIR + "/defaultlayout",
        component: DefaultLayout,
        type: "child"
    },
    {
        path: BASEDIR + "/foldedmenu",
        component: FoldedMenu,
        type: "child"
    },
    {
        path: BASEDIR + "/transparentlayout",
        component: TransparentLayout,
        type: "child"
    },
    {
        path: BASEDIR + "/lightmenu",
        component: LightMenu,
        type: "child"
    },
    {
        path: BASEDIR + "/chatopen",
        component: ChatOpen,
        type: "child"
    },
    {
        path: BASEDIR + "/layout1",
        component: Layout1,
        type: "child"
    },
    {
        path: BASEDIR + "/layout2",
        component: Layout2,
        type: "child"
    },
    {
        path: BASEDIR + "/layout3",
        component: Layout3,
        type: "child"
    },
    {
        path: BASEDIR + "/layout4",
        component: Layout4,
        type: "child"
    },
    {
        path: BASEDIR + "/layout5",
        component: Layout5,
        type: "child"
    },
    {
        path: BASEDIR + "/layout6",
        component: Layout6,
        type: "child"
    },
    {
        path: BASEDIR + "/layout7",
        component: Layout7,
        type: "child"
    },
    {
        path: BASEDIR + "/layout8",
        component: Layout8,
        type: "child"
    },
    {
        path: BASEDIR + "/layout9",
        component: Layout9,
        type: "child"
    },
    {
        path: BASEDIR + "/layout10",
        component: Layout10,
        type: "child"
    },
    {
        path: BASEDIR + "/layout11",
        component: Layout11,
        type: "child"
    },
    {
        path: BASEDIR + "/layout12",
        component: Layout12,
        type: "child"
    },
    {
        path: BASEDIR + "/layout13",
        component: Layout13,
        type: "child"
    },
    {
        path: BASEDIR + "/layout14",
        component: Layout14,
        type: "child"
    },
    {
        path: BASEDIR + "/layout15",
        component: Layout15,
        type: "child"
    },

    /*{ path: BASEDIR+"/ui-vectormaps", name: "Vector World Map", icon: "map-marker", badge: "", component: UIVectorMap },*/
    /*{ path: BASEDIR+"/ui-vectormaps-countries", name: "Vector Country Maps", icon: "map-marker", badge: "", component: Dashboard },*/
    /*{ path: BASEDIR+"/ui-googlemaps", name: "Google Maps", icon: "map-marker", badge: "", component: Maps },*/

    //{ path: "#", name: "Extensions", type: "navgroup"},

    /*{ path: BASEDIR+"/ui-imagecrop", name: "Image Cropper", icon: "file-image-o", badge: "", component: Dashboard },*/
    /*{ 
        path: "#", name: "File Uploader", icon: "upload", type: "dropdown", parentid:"",
        child: [
    
        ]
    },*/
    /*{ path: BASEDIR+"/form-fileupload-custom", component: UITypography, type: "child"},*/
    /*{ 
        path: "#", name: "Datatables", icon: "table", type: "dropdown", parentid:"",
        child: [
    
        { path: BASEDIR+"/tables-data-basic", name: "Basic Datatables"},
        { path: BASEDIR+"/tables-data-collapsedrow", name: "Collapsed Rows"},
        { path: BASEDIR+"/tables-data-download", name: "Download Formats"},
        { path: BASEDIR+"/tables-data-complexheader", name: "Complex Headers"},
        ]
    },
        { path: BASEDIR+"/tables-data-basic", component: UITypography, type: "child"},
        { path: BASEDIR+"/tables-data-collapsedrow", component: UITypography, type: "child"},
        { path: BASEDIR+"/tables-data-download", component: UITypography, type: "child"},
        { path: BASEDIR+"/tables-data-complexheader", component: UITypography, type: "child"},*/

    /*{ path: BASEDIR+"/ui-sortable", name: "Sortable Group", icon: "sort-amount-asc", badge: "", component: Dashboard },*/
];
export default dashRoutes;