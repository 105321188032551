const simplelineicons = [
    "i-user",
    "i-people",
    "i-user-female",
    "i-user-follow",
    "i-user-following",
    "i-user-unfollow",
    "i-login",
    "i-logout",
    "i-emotsmile",
    "i-phone",
    "i-call-end",
    "i-call-in",
    "i-call-out",
    "i-map",
    "i-location-pin",
    "i-direction",
    "i-directions",
    "i-compass",
    "i-layers",
    "i-menu",
    "i-list",
    "i-options-vertical",
    "i-options",
    "i-arrow-down",
    "i-arrow-left",
    "i-arrow-right",
    "i-arrow-up",
    "i-arrow-up-circle",
    "i-arrow-left-circle",
    "i-arrow-right-circle",
    "i-arrow-down-circle",
    "i-check",
    "i-clock",
    "i-plus",
    "i-minus",
    "i-close",
    "i-event",
    "i-exclamation",
    "i-organization",
    "i-trophy",
    "i-screen-smartphone",
    "i-screen-desktop",
    "i-plane",
    "i-notebook",
    "i-mustache",
    "i-mouse",
    "i-magnet",
    "i-energy",
    "i-disc",
    "i-cursor",
    "i-cursor-move",
    "i-crop",
    "i-chemistry",
    "i-speedometer",
    "i-shield",
    "i-screen-tablet",
    "i-magic-wand",
    "i-hourglass",
    "i-graduation",
    "i-ghost",
    "i-game-controller",
    "i-fire",
    "i-eyeglass",
    "i-envelope-open",
    "i-envelope-letter",
    "i-bell",
    "i-badge",
    "i-anchor",
    "i-wallet",
    "i-vector",
    "i-speech",
    "i-puzzle",
    "i-printer",
    "i-present",
    "i-playlist",
    "i-pin",
    "i-picture",
    "i-handbag",
    "i-globe-alt",
    "i-globe",
    "i-folder-alt",
    "i-folder",
    "i-film",
    "i-feed",
    "i-drop",
    "i-drawer",
    "i-docs",
    "i-doc",
    "i-diamond",
    "i-cup",
    "i-calculator",
    "i-bubbles",
    "i-briefcase",
    "i-book-open",
    "i-basket-loaded",
    "i-basket",
    "i-bag",
    "i-action-undo",
    "i-action-redo",
    "i-wrench",
    "i-umbrella",
    "i-trash",
    "i-tag",
    "i-support",
    "i-frame",
    "i-size-fullscreen",
    "i-size-actual",
    "i-shuffle",
    "i-share-alt",
    "i-share",
    "i-rocket",
    "i-question",
    "i-pie-chart",
    "i-pencil",
    "i-note",
    "i-loop",
    "i-home",
    "i-grid",
    "i-graph",
    "i-microphone",
    "i-music-tone-alt",
    "i-music-tone",
    "i-earphones-alt",
    "i-earphones",
    "i-equalizer",
    "i-like",
    "i-dislike",
    "i-control-start",
    "i-control-rewind",
    "i-control-play",
    "i-control-pause",
    "i-control-forward",
    "i-control-end",
    "i-volume-1",
    "i-volume-2",
    "i-volume-off",
    "i-calendar",
    "i-bulb",
    "i-chart",
    "i-ban",
    "i-bubble",
    "i-camrecorder",
    "i-camera",
    "i-cloud-download",
    "i-cloud-upload",
    "i-envelope",
    "i-eye",
    "i-flag",
    "i-heart",
    "i-info",
    "i-key",
    "i-link",
    "i-lock",
    "i-lock-open",
    "i-magnifier",
    "i-magnifier-add",
    "i-magnifier-remove",
    "i-paper-clip",
    "i-paper-plane",
    "i-power",
    "i-refresh",
    "i-reload",
    "i-settings",
    "i-star",
    "i-symbol-female",
    "i-symbol-male",
    "i-target",
    "i-credit-card",
    "i-paypal",
    "i-social-tumblr",
    "i-social-twitter",
    "i-social-facebook",
    "i-social-instagram",
    "i-social-linkedin",
    "i-social-pinterest",
    "i-social-github",
    "i-social-google",
    "i-social-reddit",
    "i-social-skype",
    "i-social-dribbble",
    "i-social-behance",
    "i-social-foursqare",
    "i-social-soundcloud",
    "i-social-spotify",
    "i-social-stumbleupon",
    "i-social-youtube",
    "i-social-dropbox",
    "i-social-vkontakte",
    "i-social-steam"
];

export default simplelineicons;
